import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Paper,
  IconButton,
  Typography,
  colors,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  Grid,
  TextField,
  Avatar,
  Divider
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import { ExitToApp, VpnKey } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import AsyncLocalStorage from '@createnextapp/async-local-storage';
import useRouter from 'utils/useRouter';
import Api from 'Helper/ApiHandler';

var api = new Api();

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 16px',
    borderRadius: theme.shape.borderRadius
  },
  default: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    paddingLeft: 16,
    marginRight: -8
  },
  dialogRoot: {
    padding: '10px',
    '& .MuiDialog-paper': {
      borderRadius: '10px'
    },
    '& .MuiDialog-paperWidthSm': {
      width: '250px',
      height: '154px',
      position: 'absolute',
      top: '26px',
      right: '0'
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgb(0 0 0 / 49%)'
    },
    '& .MuiPaper-elevation24': {
      boxShadow: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-paperWidthSm': {
        width: '350px',
        height: '200px'
      }
    }
  },
  dialogRootMobile: {
    '& .MuiDialog-paperWidthSm': {
      // width: '285px',
      // height: '180px',
      position: 'absolute',
      top: '105px',
      left: '-5px',
      width: '210px'
    }
  },
  addContainer: {
    // backgroundColor: '#0177FD',
    color: 'black',
    // margin: "px 0",
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 24px',
    padding: '30px 22px',
    borderBottom: '1px solid #dbd7d7'
    // [theme.breakpoints.down('sm')]: {
    //   fontSize: '14px'
    // }
  },
  avtar: {
    marginRight: '14px',
    width: '45px',
    height: '45px'
  },
  mobileAvtar: {
    marginRight: '15px',
    width: '35px',
    height: '35px'
  },
  avtarName: {
    fontSize: '14px',
    fontWeight: 500
  },
  mobileAvtarName: {
    fontSize: '12px'
  },
  tokenContainer: {
    padding: '0 25px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px'
    }
  },
  addBandWidthLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      [theme.breakpoints.down('sm')]: {
        width: '25px'
      }
    }
  },
  content: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 'none',
    padding: '0 24px',
    margin: '8px 0'
  },
  changePasswordLogo: {
    marginRight: '15px',
    color: '#0177FD',
    marginBottom: '0'
  },
  mobileChangePasswordLogo: {
    marginRight: '15px',
    marginBottom: 0,
    color: '#0177FD',
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '20px'
    }
  },
  passwordTitle: {
    fontSize: '13px',
    color: 'black',
    marginBottom: '4px'
  },
  mobilePasswordTitle: {
    color: 'black',
    marginBottom: '2px',
    fontSize: '12px'
  },
  logoutLogo: {
    marginRight: '15px',
    color: '#0177FD',
    marginBottom: 0
  },
  mobileLogoutLogo: {
    marginRight: '15px',
    color: '#0177FD',
    marginBottom: 0,
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '20px'
    }
  },
  logoutTitle: {
    fontSize: '12px',
    color: 'black',
    marginBottom: '6px'
  },
  mobileLogoutTitle: {
    color: 'black',
    marginBottom: '5px',
    fontSize: '12px'
  },
  icons: {
    color: '#E5AC00',
    width: '20px'
  }
}));

const icons = {
  default: <InfoIcon />,
  success: <CheckCircleIcon />,
  info: <InfoIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />
};

function ProfileDropdown(props) {
  const {
    className,
    open,
    onClose,
    icon,
    variant,
    message,
    mobileDropdown,
    pushTo,
    ...rest
  } = props;

  const router = useRouter();

  let url = localStorage.getItem('userProfile');
  let username = localStorage.getItem('userName');

  const handleLogout = e => {
    e.preventDefault();
    api
      .get('admin/logout')
      .then(result => {
        if (result.success === 'true') {
          // setLoginOpen(false);
          console.log(result, 'result');
          // router.history.push({
          //   pathname: `/manageUser`,
          //   search: '',
          //   state: {
          //     sessionPropsMessage: {
          //       message: result.message,
          //       type: 'success',
          //       open: true
          //     }
          //   }
          // });

          localStorage.clear();
          router.history.push('/admin/login');
        } else {
          // setLoginOpen(false);
          console.log(result, 'error');
        }
      })
      .catch(err => {
        console.log(err, 'error catch');
      });
  };

  const classes = useStyles();

  return (
    <Dialog
      // className={classes.dialogRoot}
      className={mobileDropdown ? classes.dialogRootMobile : classes.dialogRoot}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <div className={classes.addContainer}>
        <Avatar
          className={mobileDropdown ? classes.mobileAvtar : classes.avtar}
          alt="Remy Sharp"
          src={url || ''}
        />
        <Typography
          className={
            mobileDropdown ? classes.mobileAvtarName : classes.avtarName
          }>
          {username}
        </Typography>
      </div>
      <Link to="/admin/change-password" className={classes.content} onClick={onClose}>
        <DialogContentText
          className={
            mobileDropdown
              ? classes.mobileChangePasswordLogo
              : classes.changePasswordLogo
          }
          id="alert-dialog-description">
          <VpnKey className={classes.icons} />
        </DialogContentText>
        <DialogContentText
          className={
            mobileDropdown ? classes.mobilePasswordTitle : classes.passwordTitle
          }
          id="alert-dialog-description">
          Change Password
        </DialogContentText>
      </Link>
      <Divider />
      <Link className={classes.content}>
        <DialogContentText
          className={
            mobileDropdown ? classes.mobileLogoutLogo : classes.logoutLogo
          }
          id="alert-dialog-description">
          <ExitToApp className={classes.icons} />
        </DialogContentText>
        <DialogContentText
          onClick={e => handleLogout(e)}
          className={
            mobileDropdown ? classes.mobileLogoutTitle : classes.logoutTitle
          }
          id="alert-dialog-description">
          Logout
        </DialogContentText>
      </Link>
    </Dialog>
  );
}

export default ProfileDropdown;
