/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { Link, Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
  colors,
  Typography,
  useMediaQuery,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
  Container,
  Hidden,
  IconButton
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import useRouter from 'utils/useRouter';
import AppDataContext from 'Helper/AppDataContext';
const drawerWidth = 200;

const useStyles = makeStyles(theme => ({
  root: {
    // "& .MuiAppBar-colorPrimary":{
    //   backgroundColor:'none',
    // },
    // "& ..MuiPaper-elevation4":{
    //   boxShadow:'none',
    // },
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  mainHeader: {
    color: 'black',
    display: 'flex',
    height: '60px',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '79%',
    padding: 'px 0',
    paddingLeft: '35px',
    position: 'relative',
    left: '18%',
    // marginTop: '10px',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      left: '0',
      top: '0'
    }
  },
  userName: {
    marginRight: '8px',
    fontWeight: 500
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1)
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  trialIcon: {
    marginRight: theme.spacing(1)
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  },
  avtar: {
    cursor: 'pointer',
    width: '45px',
    height: '45px',
    marginRight: '20px'
  },
  textItem: {
    '& .MuiTypography-body1': {
      fontSize: '12px'
    }
  },
  // mainList: {},
  navContainer: {
    // backgroundColor: '#474646',
    // color: 'white'
  },
  saymorelogo: {
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '150px'
    }
  },
  navbar_title: {
    display: 'flex',
    marginLeft: 'auto'
  },
  navbar__title: {
    margin: '0 20px',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    transition: '0.2s ease',
    '&:hover': {
      color: '#E5AC00'
    }
  },
  menuButton: {
    marginLeft: 'auto',
    color: 'black'
    // [theme.breakpoints.up("md")]: {
    //   display: "none"
    // }
  },
  drawer: {
    flexShrink: 0,
    width: drawerWidth
  },
  drawerPaper: {
    width: drawerWidth
  }
}));

const TopBar = props => {
  const { sHeight, homeScrallToFunLocal, s, setS, onOpenNavBarMobile, className, ...rest } = props;
  const { appContext, setAppContext } = useContext(AppDataContext);
  const router = useRouter();

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let mounted = true;

    return () => {
      mounted = false;
    };
  }, []);
  // useEffect(() => {
  //   homeScrallToFunLocal(s);
  // }, []);
  const onCLickOnePage = (num) => {
    homeScrallToFunLocal(num)
    // appContext.homeScrallToFun(num);
    // router.history.push("/")
  };

  const toggleDrawer = event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen(!open);
  };
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div
      style={{
        boxShadow:
          '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        zIndex: 1000
      }}>
      <Container>
        <AppBar
          {...rest}
          className={clsx(classes.root, className)}
        // color="primary"
        >
          <Toolbar>
            <a href="/">
              <img
                className={classes.saymorelogo}
                alt="Logo"
                src={'/images/logos/sayMoreLogo.png'}
              />
            </a>
            {/* <div className={classes.flexGrow} /> */}
            <Hidden smDown>
              <div className={classes.navbar_title}>
                <a href="/">
                  <Typography
                    variant="h6"
                    style={
                      window.location.hash === '' &&
                        window.location.pathname === '/' &&
                        sHeight < 3900 && sHeight >= 0
                        ? { color: '#E5AC00' }
                        : {}
                    }
                    className={classes.navbar__title}>
                    Home
                  </Typography>
                </a>
                <div
                  onClick={() => {
                    setS(1950)
                    onCLickOnePage(1950);
                  }}>
                  {/* <a href="/#aboutus"> */}
                  <Typography
                    variant="h6"
                    style={
                      sHeight < 4400 && sHeight > 3800
                        // window.location.hash === '#aboutus'
                        ? { color: '#E5AC00' }
                        : {}
                    }
                    className={classes.navbar__title}>
                    About Us
                  </Typography>
                  {/* </a> */}
                </div>
                <div onClick={() => {
                  setS(1300)
                  onCLickOnePage(1300);
                }}>
                  <Typography
                    variant="h6"
                    style={
                      sHeight < 5800 && sHeight > 4500
                        // window.location.hash === '#contactus'
                        ? { color: '#E5AC00' }
                        : {}
                    }
                    className={classes.navbar__title}>
                    Contact Us
                  </Typography>
                </div>
                <div onClick={() => {
                  setS(-1)
                  onCLickOnePage(-1);
                  router.history.push("/page/what-we-are")
                }}>
                  {/* <Link to="/page/what-we-are" onClick={() => { setS(-1) }}> */}
                  <Typography
                    variant="h6"
                    style={
                      window.location.pathname === '/page/what-we-are'
                        ? { color: '#E5AC00' }
                        : {}
                    }
                    className={classes.navbar__title}>
                    What we are
                  </Typography>
                  {/* </Link> */}
                </div>
              </div>
            </Hidden>
            <Hidden mdUp>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={toggleDrawer}
                className={classes.menuButton}>
                <MenuIcon />
              </IconButton>

              <Drawer
                className={classes.drawer}
                variant={isMdUp ? 'permanent' : 'temporary'}
                classes={{
                  paper: classes.drawerPaper
                }}
                anchor="left"
                open={open}
                onClose={toggleDrawer}>
                <List>
                  <a href="/">
                    <ListItem button>
                      <ListItemText
                        primary={'Home'}
                        style={{ textAlign: 'center' }}
                      />
                    </ListItem>
                  </a>
                </List>
                <Divider />
                <List>
                  <a href="/#aboutus">
                    <ListItem button>
                      <ListItemText
                        primary={'About Us'}
                        style={{ textAlign: 'center' }}
                      />
                    </ListItem>
                  </a>
                </List>
                <Divider />
                <List>
                  <a href="/#contactus">
                    <ListItem button>
                      <ListItemText
                        primary={'Contact Us'}
                        style={{ textAlign: 'center' }}
                      />
                    </ListItem>
                  </a>
                </List>
                <Divider />
                <List>
                  <Link to="/page/what-we-are">
                    <ListItem button>
                      <ListItemText
                        primary={'What we are'}
                        style={{ textAlign: 'center' }}
                      />
                    </ListItem>
                  </Link>
                </List>
                <Divider />
              </Drawer>
            </Hidden>
          </Toolbar>

          {/* )} */}
        </AppBar>
      </Container>
    </div>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
