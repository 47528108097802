import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import MoneyOutlinedIcon from '@material-ui/icons/MoneyOutlined';
import CallIcon from '@material-ui/icons/Call';
import HomeIcon from '@material-ui/icons/Home';
import RateReviewIcon from '@material-ui/icons/RateReview';
import GavelIcon from '@material-ui/icons/Gavel';
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
import {
  AccountBalanceWalletOutlined,
  AssignmentIndOutlined,
  ContactsOutlined,
  PersonPinOutlined,
  ReceiptOutlined,
  SettingsOutlined,
  SupervisedUserCircleOutlined
} from '@material-ui/icons';

export default [
  {
    title: 'MANAGEMENT',
    pages: [
      // -------------------------------------- Organization --------------------------------------
      // {
      //   title: 'Dashboard Analytics',
      //   href: /admin/DashboardAnalytics',
      //   icon: HomeIcon
      // },
      {
        title: 'Manage Users',
        href: '/admin/manageUser',
        icon: PeopleIcon
      },
      {
        title: 'Manage Leaders',
        href: '/admin/manageLeaders',
        icon: ContactsOutlined
      },
      {
        title: 'Manage Employees',
        href: '/admin/manageEmployees',
        icon:  AssignmentIndOutlined
      },
      {
        title: 'Manage Advisors',
        href: '/admin/manageAdvisors',
        icon: PersonPinOutlined
      },
      {
        title: 'Wallet Withdraw',
        href: '/admin/walletwithdrawrequest',
        icon: AccountBalanceWalletOutlined
      },
      {
        title: 'Transactions',
        href: '/admin/transactions',
        icon: ReceiptOutlined
      },
      {
        title: 'Mutual Funds',
        href: '/mutualfunds',
        icon: MoneyOutlinedIcon,
        children: [
          {
            title: 'Orders',
            href: '/admin/mutualfunds/orders'
          },
          {
            title: 'Scripts',
            href: '/admin/mutualfunds/scripts'
          },
          {
            title: 'Categories',
            href: '/admin/mutualfunds/categories'
          },
          {
            title: 'Sectors',
            href: '/admin/mutualfunds/sectors'
          }
          // {
          //   title: 'Withdraw Request',
          //   href: '/admin/withdrawalrequest'
          // }
        ]
      },
      {
        title: 'Co-Management Fund',
        href: '/mutualfunds',
        icon: LocalAtmOutlinedIcon,
        children: [
          {
            title: 'Orders',
            href: '/admin/kitty/orders'
          },
          {
            title: 'Monthly Schemes',
            href: '/admin/kitty/scheme'
          },
          {
            title: 'Lumpsum Scheme',
            href: '/admin/kitty/lumsumscheme'
          },
          {
            title: 'Withdraw Request',
            href: '/admin/kitty/withdrawalrequest'
          },
          {
            title: 'Sectors',
            href: '/admin/kitty/sectors'
          },
          {
            title: 'Manage Wealth Account',
            href: '/admin/kitty/managewealthaccount'
          }
        ]
      },
      // {
      //   title: 'Withdraw Request',
      //   href: '/admin/withdrawalrequest',
      //   icon: AccountBalanceWalletOutlined
      // },
      {
        title: 'Manage',
        href: '/manage',
        icon: SettingsOutlined,
        children: [
          {
            title: 'Mobile Notification',
            href: '/admin/manage/mobileNotification'
          },
          {
            title: 'Send Push Notification',
            href: '/admin/manage/pushNotification'
          },
          {
            title: 'Our Partners',
            href: '/admin/manage/ourPartners'
          },
          {
            title: 'Mobile Banners',
            href: '/admin/manage/mobileBanner'
          },
          {
            title: 'Commission',
            href: '/admin/manage/Commission'
          }
        ]
      },
      {
        title: 'NOC from Indian Govt',
        href: '/admin/noc',
        icon: GavelIcon
      },
      {
        title: 'Testimonials',
        href: '/admin/manage/testimonials',
        icon: RateReviewIcon
      },
      {
        title: 'ContactUs',
        href: '/admin/manage/contactUs',
        icon: CallIcon
      }
    ]
  }
];
