import React, { Fragment, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, Drawer, Paper } from '@material-ui/core';
import { Hidden } from '@material-ui/core';
import useRouter from 'utils/useRouter';
import { Navigation } from 'components';
import navigationConfig from './navigationConfig';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import AsyncLocalStorage from '@createnextapp/async-local-storage'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    backgroundColor: '#474646',
    '& .makeStyles-active-40': {
      // background: "rgb(51 141 216)",
      // background: "rgb(51 141 216)",
      backgroundImage: "linear-gradient(261.94deg, #FFD24C 0%, #FFD24C 46.4%, #FFEF33 100%)"
    },
    '& .MuiList-padding': {
      paddingBottom: "0px"
    }
  },
  content: {
    padding: theme.spacing(2),
    "& .MuiButton-label": {
      fontSize: "13px"
    }
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2)
  },
  navigation: {
    marginTop: theme.spacing(2),
  },
  logout: {
    color: "white",
    textAlign: "left",
    '& .MuiButton-label': {
      display: "flex",
      justifyContent: "flex-start"
    },
    textTransform: "capitalize",
    marginLeft: "5px",
    padding: "10px",
  }
}));

const NavBar = props => {
  const { openMobile, onMobileClose, className, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);

  const removeToken = () => {
    // removeItem
    console.log("logout............")
    AsyncLocalStorage.removeItem("authToken")
    router.history.push('/organization/login');
  }

  const navbarContent = (
    <div className={classes.content}>
      <nav className={classes.navigation}>
        {navigationConfig.map(list => (
          <Navigation
            component="div"
            key={list.title}
            pages={list.pages}
            title={list.title}
          />
        ))}
        {/* <Button
          startIcon={<PowerSettingsNewIcon />}
          fullWidth
          className={classes.logout}
          onClick={() => { removeToken() }}
        >
          Logout
        </Button> */}
      </nav>
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div
            {...rest}
            className={clsx(classes.root, className)}
          >
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
