import React, { useEffect } from 'react';
import {
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import Api from '../../Helper/ApiHandler';
import { validate } from 'validate.js';
var api = new Api();

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'Category name is required' }
  },
  ScriptType: {
    presence: { allowEmpty: false, message: 'Script Type is required' }
  }
};

const useStyles = makeStyles(() => ({
  root: {},
  dialogButtonHeader: {
    display: 'flex',
    justifyContent: 'center',
    padding: '40px 10px 10px 10px',
    '& .MuiButton-root': {
      // textTransform: 'capitalize'
    }
  },
  divider: {
    '& .MuiDivider-root': {
      backgroundColor: '#0000002b'
    }
  },
  dialogRoot: {
    padding: '10px',
    '& .MuiDialog-paper': {
      // height: '250px',
      // overflow: "visible"
      borderRadius: '10px'
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgb(0 0 0 / 55%)'
    },
    '& .MuiPaper-elevation24': {
      // boxShadow: 'none'
    }
  },
  btnSave: {
    borderRadius: '5px',
    fontSize: '12px',
    backgroundImage:
      'linear-gradient(261.94deg, #FFD24C 0%, #FFD24C 46.4%, #FFEF33 100%)',
    color: 'white',
    textTransform: 'uppercase',
    width: '120px'
  },
  btnCancel: {
    borderRadius: '5px',
    fontSize: '12px',
    width: '120px'
  },
  flexWithPading: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
      fontSize: '14px'
    }
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& .MuiTypography-body1': {
      fontWeight: 'bold',
      fontSize: '16px'
    }
  },
  title: {
    fontWeight: 500
  },
  subTitle: {
    opacity: 0.3,
    fontWeight: 500
  }
}));

function AddKittySectorModal(props) {
  const classes = useStyles();
  const {
    userType,
    componentName,
    setSessionMessage,
    slug,
    onload,
    onClose,
    open
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false });

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleClose = () => {
    onClose();
  };

  const handleFieldChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleSubmit = async event => {
    event.preventDefault();

    if (formState.isValid === true) {
      setDisabled(true);
      setLoading(true);
      api
        .post(`admin/addsector`, {
          data: {
            ...formState.values,
            investment_categoryId: 5
          }
        })
        .then(result => {
          if (result.success === 'true') {
            setSessionMessage({
              message: result.message,
              type: 'success',
              open: true
            });
            onClose();
            onload();
          } else {
            console.log(result, 'result');
            setSessionMessage({
              message: result.message,
              type: 'error',
              open: true
            });
          }
          setDisabled(false);
          setLoading(false);
        })
        .catch(err => {
          console.log(err, 'error catch');
          setSessionMessage({
            message: err.message,
            type: 'error',
            open: true
          });
          setDisabled(false);
          setLoading(false);
        });
    }
  };

  return (
    <Dialog
      className={classes.dialogRoot}
      onClose={handleClose}
      // maxWidth="xs"
      open={open}>
      <Grid container justify="center" style={{ padding: '20px' }}>
        <Grid item xs={12} className={classes.flex}>
          <Typography>Add Sector</Typography>
        </Grid>
      </Grid>
      <div className={classes.divider}>
        <Divider />
      </div>
      <form onSubmit={handleSubmit}>
        <Grid container justify="center" style={{ padding: '20px' }}>
          <Grid item xs={12} className={classes.flexWithPading}>
            <FormControl fullWidth>
              {/* <InputLabel id="script-type-label">Script Type</InputLabel> */}
              <Select
                // labelId="script-type-label"
                displayEmpty
                id="script-type"
                name="ScriptType"
                value={formState.values.ScriptType || ''}
                onChange={handleFieldChange}
                error={hasError('ScriptType')}
                variant="outlined">
                <MenuItem disabled value="">
                  Script Type*
                </MenuItem>
                <MenuItem value="Monthly">Monthly scheme</MenuItem>
                <MenuItem value="Lumpsum">Lumpsum scheme</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} className={classes.flexWithPading}>
            <TextField
              required
              size="small"
              InputLabelProps={{
                classes: {
                  asterisk: classes.asterisk
                }
              }}
              fullWidth
              error={hasError('name')}
              type="text"
              helperText={hasError('name') ? formState.errors.name[0] : null}
              label="Sector name"
              name="name"
              onChange={handleFieldChange}
              value={formState.values.name || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} className={classes.flexWithPading}>
            <TextField
              required
              size="small"
              InputLabelProps={{
                classes: {
                  asterisk: classes.asterisk
                }
              }}
              fullWidth
              error={hasError('description')}
              type="text"
              // helperText={hasError('name') ? formState.errors.description[0] : null}
              label="Sector description"
              name="description"
              onChange={handleFieldChange}
              value={formState.values.description || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.dialogButtonHeader}>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  handleClose();
                }}
                className={classes.btnCancel}>
                Cancel
              </Button>
              &nbsp;&nbsp;
              <Button
                color="primary"
                variant="contained"
                className={classes.btnSave}
                type="submit"
                disabled={formState.isValid ? disabled : true}>
                Save &nbsp;
                {loading ? <CircularProgress size={20} /> : ''}
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
}

export default AddKittySectorModal;
